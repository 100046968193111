
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rws-su-ui-primary: mat.define-palette(mat.$indigo-palette);
$rws-su-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$rws-su-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rws-su-ui-theme: mat.define-light-theme((
  color: (
    primary: $rws-su-ui-primary,
    accent: $rws-su-ui-accent,
    warn: $rws-su-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rws-su-ui-theme);


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$virtual-campus-v2-primary: mat.define-palette(mat.$indigo-palette);
$virtual-campus-v2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$virtual-campus-v2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$virtual-campus-v2-theme: mat.define-light-theme((
  color: (
    primary: $virtual-campus-v2-primary,
    accent: $virtual-campus-v2-accent,
    warn: $virtual-campus-v2-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($virtual-campus-v2-theme);

/* You can add global styles to this file, and also import other style files */
@import 'helpers/variables/palette';
@import 'helpers/mixins/grid';
@import 'core/reset';
@import 'core/grid';
@import 'core/typography';
@import 'core/tables';
@import 'forms/buttons';
@import 'forms/form-elements';
@import 'forms/form-validation';
@import 'core/base';
@import 'core/header';
@import 'core/main';
@import 'core/footer';
@import 'icons/user';
@import 'icons/menu';
@import 'icons/close';
@import 'icons/search';
@import 'icons/alert';
@import 'new_global';
@import 'icons/chat';
@import 'icons/favourite';
@import 'responsive.scss';
//@import '../../node_modules/videogular2/fonts/videogular.css';

html, body { height: 100%; }
body { margin: 0;}

html, body { height: 100%; }
body { margin: 0;}

body {
  background-image: linear-gradient(180deg, #CBDFEF, #FFFFFF); /* Linear gradient */
  background-attachment: fixed; /* Fixed background attachment */
  background-size: cover; /* Ensure gradient covers the entire viewport */
  color: var(--primary-color);
  margin: 0;
}
