@import "../helpers/mixins/typography";
@import "../helpers/variables/base";
html,
body,
button,
input,
table,
td,
th {
  font-family: $inter;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
article,
aside,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
  vertical-align: top;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter'; /* Retaining existing font-family */
  font-size: 62.5%; /* Retaining existing font-size */
  // overflow-y: scroll; /* Retaining existing overflow */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Retaining existing tap highlight color */
  -webkit-text-size-adjust: 100%; /* Retaining existing text size adjust */
  -ms-text-size-adjust: 100%; /* Retaining existing text size adjust */
}
body {
  background-image: linear-gradient(180deg, #CBDFEF, #FFFFFF); /* Linear gradient */
  background-attachment: fixed; /* Fixed background attachment */
  background-size: cover; /* Ensure gradient covers entire viewport */
}
fieldset {
  border: none;
  padding: 0;
}

b,
strong {
  font-weight: 600;
}

img {
  border: 0;
}

abbr[title] {
  cursor: help;
}

// Link styles
a:link {
  color: $link-colour;
}

a:visited {
  color: $link-visited-colour;
}

a:hover {
  color: $link-hover-colour;
}

a:active {
  color: $link-active-colour;
}

// Set focus styles
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}

// a:focus { 
//   background-color: $focus-colour;
//   outline: 3px solid $focus-colour;
//   color: #000!important;
// }

a.logo:focus {
  background-color: none;
  outline: 0;
}
input:focus,
textarea:focus,
select:focus,
button:focus,
// input[type=file]:focus-within {
//   outline: 3px solid $focus-colour;
//   border: 2px solid #000;
//   outline-offset: 0;
// }
// input:focus,
// textarea:focus,
// select:focus,
// input[type=file]:focus-within {
//   box-shadow: inset 0 0 0 2px;
// }
button:focus {
  box-shadow: none;
  border: 0;
}
.nomargintop{
  margin-top: 0!important;
}
.nomarginbottom {
  // margin-bottom: 0 !important;
  margin-bottom: -30px;
}
.mt25 {
  margin-top: 25px;
}
.mb25 {
  margin-bottom: 25px;
}
.mr15 {
  margin-right: 15px;
}
.tabWrapper {
  ul {
    li {
      a {
        font-size: 16px;
          @include media(tablet) {
              font-size: 19px;
          }
      }
    }
  }
}
.tabsMessage {
  @include core-19();
}
.relative{
  position: relative;
}

.changeCaseOfTitle{
  .heading-xlarge{
    text-transform: inherit !important;
  }
}

.search-icon-align{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1875;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  color: #fff;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #000;
  border-color: #000;
  position: absolute;
  top: 0px;
  right: -5.5px;
  width: 82px;
  height: 39px;
  cursor: pointer;
}
// .searchPosition {
//   right: -82px !important;
// }

.plan-notification{
  margin-top: 8px;
  font-size: 16px;
  img{
    width: 81%;
    float: right;
    margin-top: 9px;
  }
}
.sign-in-page {
  .column-one-half{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px!important;
  }
}
label{
  // font-family: 'helvetica_bold';
}
.page-title{
  font-size: 30px;
}
.course-title{
  font-size: 19px;
  font-weight: 400;
   @include sentence-case();
}

.header.sub-menu{
  border: 0px!important;
  display: block;
  float: right;
  margin-bottom: 12px;
  margin-right: 15px;
  margin-top: 10px;
  @include media(mobile) {
    width: 100%;
    margin-right: 0;
  }
  .navigation-item{
      padding: 2.5px 10px;
      font-size: 14px;
      @include media(mobile) {
        width: 27%;
      }
  }
}
.page-title{
  font-size: 30px;
  // font-family: 'helvetica_bold';
}
.course-title{
  font-size: 36px;
  font-weight: 700;
  // font-family: 'helvetica_bold';
  @include sentence-case();
}
.width100{
  width: 100%;
}
.text-right{
  text-align: right;
}

.tag {
  display: inline-block;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #dee0e2;
  padding: 5px 8px 4px;
  margin-bottom: 5px;
}

.status-tag {
  display: inline-block !important;
  outline: 2px solid transparent;
  outline-offset: -2px;
  color: #fff;
  background-color: $black;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  padding: 5px 8px 3px;
  @include bold-16();
  margin: 2px;
  font-family: 'Inter';
  width: auto;
  text-align: center;

  &.status-expired {
    background-color: $grey-1;
  }
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: #1d70b8;
  }
  &.yellow {
    color: #594d00;
    background: #fff7bf;
  }
  &.newgreen {
    color: #005a30;
    background: #cce2d8;
  }
}

.panel-wrapper {
  color: $white;
  background-color: $green;
  padding: 35px;
  text-align: center;
}

.tag-list {
  margin: 10px 0px;
  .tag {
    margin-right: 10px;
  }
}

.close-icon-align {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

.modal-open {
  overflow: hidden;
}
.left {
 float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}
.fade-in{
  display: none;
}
//style for content image-viewer
#image-viewer{
  .img-container {
    border: 1px solid #ccc;
    background: #ddd!important;
    min-height: 300px;
    #ngx-fs-btn{
      display: none;
    }
    button{
      background-color: rgba(255, 255, 255, 0);
      border-color: #0b0c0c;
      opacity: 1;
      &:nth-of-type(1):not(#ngx-fs-btn){
        top: 165px;       
      }
      &:nth-of-type(2):not(#ngx-fs-btn){
        top: 115px;
      }
      &:nth-of-type(3):not(#ngx-fs-btn){
        top: 65px;
      }
      &:nth-of-type(4):not(#ngx-fs-btn){
        top: 15px;
      }
      &:hover{
        background-color: rgba(255, 255, 255, 0);
      }
      &:focus{
        outline: 0;
        border: 2px solid #0b0c0c;
        box-shadow: 0 0 1px 4px #ffdd00;
      }
    }
  }
}
.changeCaseOfTitle{
  .heading-xlarge{
    text-transform: inherit!important;
    padding-left: 0px;
    @media all and (max-width: 1024px) {
      width: 80% !important;
    }
  }
}
.doNotChangeCaseOfTitle {
  .heading-xlarge {
    text-transform: none;
    &:first-letter {
      text-transform: none;
    }
  }
}
#induction-questionnaire{
  legend{
    // font-family: 'helvetica_bold'!important;
  }
}
//*dark mode*/
.logo-icon{
  display: none;
}
.dark-mode-button{
  position: absolute;
  right: 12px;
  top: 7px;
  margin-top: 6.6px;
}
.dark-mode{
  background: #121212;
  color: #fff!important;
  footer{
      background: #121212 !important;
      color: #ffffff !important;
      nav .col-nav a{
        color: #ffffff !important;
      }
      nav .col-nav a:link{
        color: #ffffff !important;
      }
      .footer__links a.nav__item{
        color: #ffffff !important;
      }
    }
  
  .logo-icon{
    display: block;
  }
  .header .header-container .rightNav a {
    color: #fff;
  }
  .header-logotype, .footer-logo{
    img {
      filter: brightness(0) invert(1);
    }
  }
  .notice-board-banner{
     .mbanner-message{
       .heading-m {
        color: #fff!important;
      }
    }
  }
  .pathway-label{
    color: #000000!important;
  }
  .pathway-label-behaviour-and-attitudes{
    .pathway-label{
      color: #ffffff!important;
    } 
  }
  .entry-pathway-label{
    color: #121212!important;
  }
  .together-mode-modal-background div.modal-container .together-mode-modal {
    background-color: #28282B!important;
    .top-panel{
      background-color: #666!important;
    }
  }
  .custmodal-dialog {
    background-color: #28282B!important;
    .heading-large p{
      color: #fff;
    }
  }
  .goals-alert-heading, .banner__message, .moj-banner-message, .errorMessagesdiv input[type=checkbox] + .errorMessages p{
    color: #fff!important;
  }
  .goals-alert-container{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .heart-favourite-false{
    filter: brightness(0) invert(1);
  }
  .status-tag{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .upload-section{
    #id-file{
      color: #999!important;
    }
  }
  .link-back{
    color: #fff!important;
    text-decoration-color: none!important;
    border-bottom: 1px solid #fff;
    &::before{
      border-right-color: #fff!important;
    }
  }
  .tabWrapper {
    ul {
      li{
        background-color: rgba(255, 255, 255, 0.1)!important;
        a{
          color: #fff!important;
        }
      }
      li.active{
        background: #121212!important;
      }
    }
  }
  .back-nav-content-container .nav-content-container .nav-container nav.side-navigation ul.side-navigation__list li.side-navigation__item a.side-navigation__item-active{
    background-color: rgba(255, 255, 255, 0.1)!important;
  }
  .header{
      .header-logotype{
        .logo-icon{
          display: block;
          position: absolute;
          height: 41px!important;
          z-index: 9;
        }
          img.logo-name{
              filter: brightness(0) invert(1);
          }
      }
      .header-navigation-container-background{
        background-color: rgba(255, 255, 255, 0.1);
        li{
          .navigation-item{
            color: #69ADE8!important;
            &:focus{
              color: #0b0c0c!important;
            }
          }
        }
        li.active{
          border-bottom: 4px solid #69ADE8;
        }
      }
  }
  .footer{
      .footer-logotype{
          img{
              filter: brightness(0) invert(1);
          }
      }
  }
  .header, .main-content, .footer{
      background: #121212;
  }
  .form-label, .form-label-bold, .form-label-b19{
      color: #fff;
  }
  .govuk-error-summary{
      color: #fff!important;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    color: #fff!important;
    background-color: #121212;
    margin-bottom: 0px !important;
    padding-bottom: 20px !important;
  }
  .sv_main .sv_container .sv_body .sv_nav {
    background: #121212;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_title {
    color: #fff!important;
  }
  .sv_qcbc.sv_qcbx .sv_q_checkbox .sv_q_checkbox_label input[type=checkbox] + .checkbox-material {
    border: 2px solid #fff;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup .sv_q_radiogroup_label input[type=radio] + .circle {
    border: 2px solid white;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.checked .sv_q_radiogroup_label input[type=radio] + .circle .sv-hidden {
    filter: brightness(0) invert(1);
  }
  .sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text {
    background: white !important;
    color: #121212 !important;
  }
  sv_q_radiogroup .sv-q-col-1 checked {
    background: white !important;
  }
  .sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text {
    color: white !important;
  }
  .sv_main.sv_default_css .sv_header,.sv_main.sv_default_css .sv_body {
    background: #121212;
  }
  .status-spacer, .entry-pathway-label {
    border: 3px solid #1a1a1a !important;
  }
  .entry-subtitle ,.entry-induction-content{
    color: rgb(80, 90, 95) !important;
  }
  .slider-track {
    background: rgba(250, 248, 248, 0.42);
  }
  .upper-container .months-container .previous-month {
    border-left: 5px solid transparent !important;
  }
  .sv_qcbc.sv_qcbx div.sv_q_checkbox.checked .sv_q_checkbox_label input[type=checkbox] + .checkbox-material {
    border: 2px solid #fff;
  }
  .status-spacer {
    background: #1a1a1a !important;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_q_text_root {
    background: white;
    color: #121212;
  }
  .display-panel{
    background: #121212!important;
  }
  .moj-side-navigation__list{
    li{
      &:hover{
        color: #121212!important;
      }
    }
    li.active{
      color: #121212!important;
    }
  }
  .message-item.message-item--received{
    color: #121212!important;
  }
  .form-hint{
    color: #9FA5A8;
  }
  a:link, .favourite-link, .link{
    color: #69ADE8!important;
  }
  .today-label-container {
    .today-label{
      color: #E892BA!important;
    }
  }
  .pathway-timeline.pathway-timeline-education-training-and-employment{
    background-color:  rgba(251,210,189) !important;
  }
  .pathway-timeline.pathway-timeline-finance{
    background-color:  rgba(184,224,221) !important;
  }
  .pathway-timeline.pathway-timeline-health-and-wellbeing {
    background-color:  rgba(199,220,238) !important;
  }
  .pathway-timeline.pathway-timeline-housing {
    background-color:  rgba(251,221,234) !important;
  }
  .pathway-timeline.pathway-timeline-drugs-and-alcohol {
    background-color:  rgba(215,221,196) !important;
  }
  .pathway-timeline.pathway-timeline-family {
    background-color:  rgba(208,209,229) !important;
  }
  .pathway-timeline.pathway-timeline-behaviour-and-attitudes {
    background-color:  rgba(219,185,216) !important;
  }
  .form-control#id-selector, .form-control#educationTypes ,select#id-education{
    color: #fff!important;
    background-color: #121212 !important;
    border: 2px solid #fff !important;
  }
  #surveyElement .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper .sv_q_dropdown_control {
    color: #fff!important;
    background-color: #121212 !important;
    border: 2px solid #fff !important;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
    color: #fff!important;
  }

  //session container for login and auth pages
  .sessions_container{
    background-color: rgb(65 65 65) !important;
  }

  #forgot-password{
    background-color:rgba(255, 255, 255, 0.1) !important;
  }

  //header and menu bg color
  .header .menu, .caption-div > div, .caption1-div div, .caption2-div div, .caption3-div div, .caption4-div div, .caption5-div div, .mini-card{
    background-color: #ffffff1a !important;
    color: #FFF !important;
  }

   // message chat bg
   .message-item--sent{
    background-color: #191919!important;
    color: #FFF !important;

    div{
      color: #FFF !important;
    }
  }
  .message-item--sent {
    &::after{
        border-left: 1em solid #191919 !important;
        border-bottom-left-radius: 1.75em 1.5em !important;
    }
  }

  //timeline data container
  .data-container, .right-container{
    background-color: #595959 !important;
  }

  .entry-subtitle, .entry-title, /*  main heading color -> */ .main-heading, .sub-heading, .govuk-caption-m, .govuk-heading-m, .govuk-inset-text{
    color: white !important;
  }
  
  .status-tag.newgreen{
    color: #00924e !important;
  }

  //course page 
  .courses-search-section{
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  
  .caption-div div{
    background-color: rgba(255, 255, 255, 0.1) !important;
    span{
      color: #FFF !important;
    }
  }

  .content-card.course{	
    .content-card-wrapper {	
      figcaption{	
        .caption-div{	
          div{	
            background-color: rgba(255, 255, 255, 0.1) !important;	
            span{	
              color: #FFF !important;
            }	
          }	
        }	
      }	
    }	
  } 
  .wallet-wrapper,.cv-builder,.jobs-for-prison-leavers{
    img{
      filter: brightness(0) invert(1);
    }
  }
  .content-card-wrapper {
    figcaption{	
      .caption-div{	
        background-color: rgba(255, 255, 255, 0.1) !important;
        span{	
          color: #FFF!important;	
        }	
      }	
    }
  }
  .content-card.category {
    .content-card-wrapper {
      img{
        filter: brightness(0) invert(1);
      }
    }
  }
  .content-card.recommended > figure > figcaption > .caption-div{
    div{
      background-color: rgba(0, 0, 0, 0.737) !important;
      span{	
        color: #FFF !important;	
      }	
    }
  }


  .job-wrapper.recommended > figure > figcaption span, 
  .job-wrapper.recommended > figure > figcaption > div
  span{
    background-color:rgba(255, 255, 255, 0.1) !important;
    color: #FFF !important;
  }
  .cv-wrapper.recommended > figure > figcaption span,
  .cv-wrapper.recommended > figure > figcaption > div 
  span{
    background-color:rgba(255, 255, 255, 0.1) !important;
    color: #FFF !important;
  }

  
  input[type="radio"] ~ label {
    color: #FFF !important;
  }

  input[type="radio"] ~ label::before {
    color: #FFF !important;
    border: 2px solid #FFF !important;
  }

  input[type="radio"] ~ label::after {
    background-color: #FFF !important;
  }

  .gds-radio-wrapper{
    input[type="radio"] ~ label::after {
      border: 10px solid #FFF !important;
    }
  }

  .extra-radio-label{
    color: #000 !important;
  }

  .table-heading{
    background-color: #3c3c3c !important;
    color: #FFF !important;
  }

  .myAssignee {
    background-color: #595959 !important;
    tr, td {
      background-color: #595959 !important;
      color: #FFF !important;
    }
  }

  .gds-modal-dialog{
    background-color: #292929 !important;
  }

  .govuk-heading-l{
    color: #FFF !important;
  }

  .form-label-count, .form-hint, .form-label{
    color:  #D6D6D6 !important;
  }

  .accordion:after{
    color: #FFF !important;
  }

  .entry-label{
    color: #FFF !important;
  }

  .care-plan-questions-list .checkbox-wrapper input[type=checkbox] ~ label::before{
    border: 2px solid #FFF !important;
  }

  .govuk-warning-icon{
    color: #000 !important;
    background-color: #FFF !important;
  }

  .entry-pathway-label, .pathway-label{
    color: #FFF!important;
  }

  .drop-down-button.active{
    color: #a6afe5 !important;
  }

  a{
    color: #ABB4E7 !important;
  }
}
.dark-mode.customdarkMode {
  .header-logotype {
    .app-logo {
      filter: inherit !important;
    }
  }
}


