@use 'sass:math';
@import "../helpers/mixins/typography";
// @import "src/styles/helpers/variables/base";

// Core font sizes
.font-xxlarge {
  @include core-80;
}

.font-xlarge {
  @include core-48;
}

.font-large {
  @include core-36;
}

.font-medium {
  @include core-24;
}

.font-small {
  @include core-19;
}

.font-xsmall {
  @include core-16;
}

// Bold font sizes
.bold-xxlarge {
  @include bold-80();
}

.bold-xlarge {
  @include bold-48();
}

.bold-large {
  @include bold-36();
}

.bold-medium {
  @include bold-24();
}

.bold-small {
  @include bold-19();
}

.bold-xsmall {
  @include bold-16();
}

.bold {
  // font-family: 'helvetica_bold';
}

// Headings
.heading-xlarge {
  @include bold-48();
  @include sentence-case();
  display: block;
  margin-top: 0;
  margin-bottom: 50px;
  @include media(tablet) {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .heading-secondary {
    @include heading-27();

    display: block;
    color: $secondary-text-colour;
  }
}
.heading-large {
  @include bold-36();
  display: block;
  margin-top: 0;
  margin-bottom: 30px;

  .heading-secondary {
    @include heading-24();

    display: block;
    color: $secondary-text-colour;
  }
  @include media(tablet){
    @include bold-24();
  }
  @include media(desktop){
    @include bold-36();
  }
}
.heading-medium {
  @include bold-24();	
  display: block;	
  margin-top: 0px;	
  margin-bottom: 20px;	
  @include media(tablet) {	
    margin-top: 0px;	
    margin-bottom: 20px;	
  }
}

.heading-small {
  @include bold-19();
  display: block;
  margin-top: em(10, 16);
  margin-bottom: em(5, 16);

  @include media(tablet) {
    margin-top: em(20, 19);
  }
}

.heading-xsmall {
  @include bold-16();
  display: block;
  margin-top: em(10, 16);
  margin-bottom: em(16, 16);
  white-space: normal;

  @include media(tablet) {
    margin-top: em(15, 19);
    margin-bottom: em(20, 19);
  }
}

// Text
// p,
// .body-text {
//   margin-top: em(5, 16);
//   margin-bottom: em(20, 16);
//   font-size: 16px;
//   @include media(tablet) {
//     margin-top: em(5);
//     margin-bottom: em(20);
//     font-size: 19px;
//   }
// }

.body-text {
  display: block;
}

// Lede, or intro text
.lede {
  @include core-24;
}

.text {
  max-width: 30em;
}

.text-secondary {
  color: $secondary-text-colour;
}
// ul {
//   list-style: none;
//   margin-bottom: 0;
//   height: 32px;
// }
// li {
//   display: inline-block;
//   height: 29px;
//   border-bottom: 4px solid transparent;
//   &:first-child {
//     margin-left: 0;
//   }
// }
// #sign-in {
//   ul {
//     height: auto;
//     li {
//       display: list-item;
//       height: auto;
//       border-bottom: 4px solid transparent;
//       margin-left: 15px;
//     }
//   }
// }
.navigation-item {
  display: inline-block;
  width: calc(100% - 40px);
  padding:  ($gutter / 4) ($gutter / 3) ($gutter / 4) $gutter;
  margin: math.div($gutter , 5) 0;
  text-align: center;
  color: $black;
  box-shadow: 0 -2px transparent, 0 4px transparent;

  &:hover {
    outline: none;
    text-decoration: underline;
    color: $black;
  }

  &:active,
  &.active {
    // background: $yellow;
    color: $black;
    outline: none;
    // box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
    text-decoration: none;
    // font-family: 'helvetica_bold';
  }

  &:focus {
    text-decoration: none;
    color: $black;
    outline: none;
    // font-family: 'helvetica_bold';
    // background: $yellow;
    // box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
  }

  @include media(tablet) {
    width: auto;
    color: $black;
    padding: 0;
    margin: 0 6px;
    float: left;
    text-align: center;
    cursor: pointer;
    line-height: 1.3;
  }
}
// li.active {
//   border-bottom: 4px solid $black;
//   .navigation-item:hover, .navigation-item:focus, .navigation-item:active, .navigation-item.active {
//     text-decoration: none;
//   }
//   .navigation-item {
//     font-family: 'helvetica_bold';
//   }
// }

a.link-back {
  text-decoration: none !important;
  font-size: 16px;
  // padding-top: 22px;
}

// Back link styles - with left pointing arrow

.link-back { 
  @include inline-block;
  cursor: pointer;
  @include core-16;
  margin: 15px 0 !important;
  margin-top: $gutter-half;
  //margin-bottom: $gutter-half;
  color: $black;
  
  &:link,
  &:visited,
  &:hover {
    color: $black
  }
  a#{&}:focus,
  &:active {
    color: $black;
    outline: 3px solid transparent;
    background-color: #fd0;
    -webkit-box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
    box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
    text-decoration: none;
  }

  text-decoration: none;
  border-bottom: 1px solid $black;

  // Back arrow - left pointing black arrow
  &::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 6px solid $text-colour;
    border-bottom: 5px solid transparent;
    margin-right: 5px;
  }
}

.link, a:link {
  margin-bottom: 0;		  
  color: $link-colour;  
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
  }
.link:visited,
a:visited {
color: $link-visited-colour;
}
.link:hover,
a:hover {
    color: $link-hover-colour;
}
.link:active,
a:active {
    color: $link-colour;
}

// Horizontal rule style
hr {
  display: block;
  background: $border-colour;
  border: 0;
  height: 1px;
  margin-top: $gutter;
  margin-bottom: $gutter;
  padding: 0;
}

// Notice, text with an icon to the left
.notice {
  @include contain-floats();
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -17px; // Half the height of the important icon
  }

  strong {
    display: block;
    padding-left: (35 + 30) + px;
    margin-left: -$gutter-half;
  }
}

// Data
.data {
  margin-top: em(5, 16);
  margin-bottom: em(20, 16);

  @include media(tablet) {
    margin-top: em(5, 19);
    margin-bottom: em(20, 19);
  }
}

.data-item {
  display: block;
  line-height: 1;
}



.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
.grid-row .column-one-half .main-heading{
  font-size: 27px;
  font-weight: 400;
  color: #626A6e;
  // font-family: "Helvetica", "Arial", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", "Lucida Grande", sans-serif;
}